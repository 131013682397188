.addresslist-radio > div > label > span:last-child {
  width: 100%;
}

.ant-affix .total-affix {
  background-color: #f5f5f5;
}

.ant-form-item-label {
  text-align: left;
  height: 30px;
}

.panel-no-header > .ant-collapse-header {
  display: none !important;
}

.pickup-stores .ant-select-selector {
  height: auto !important;
}
.pickup-stores .ant-select-selector .ant-select-selection-item::after {
  content: none;
}

.section-border {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
