#cart-view .ant-dropdown-menu-item:hover {
  background-color: unset;
}

#cart-toast .ant-notification {
  width: 280px;
}

/* cart toast close icon position */
#cart-toast .ant-notification-notice-close {
  top: 3px;
  right: 5px;
}

@media(min-width: 1120px) {
  #cart-toast .ant-notification {
    right: calc((100vw - 1120px)/2) !important;
  }
}

@media(max-width: 767px) {
  #cart-toast .ant-notification {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 300px) {
  .small-cart {
    margin-right: 20px;
  }
}

.cart-list-scroll {
  display: block;
  max-height: calc(100vh - 370px);
  overflow-y: auto !important;
  text-align: initial;
}
