.priority-shipping-toc {
  background-color: #F5F5F5;
}

.priority-shipping-toc .ant-anchor-link-active {
  background-color: #CB0000 !important;
}

.priority-shipping-toc .ant-anchor-link-active>a {
  color: white !important;
}

.priority-shipping-toc .ant-anchor-link {
  display: flex;
  align-items: center;
  font-size: 12px;
  height: 40px;
}

.priority-shipping-toc .ant-anchor-link>a {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 30px;
  color: rgb(33, 33, 33);
  font-weight: 700;
}

@media (max-width: 575px) {
  .priority-shipping-slider-image {
    max-width: 60%
  }
}

@media (min-width: 576px) {
  .priority-shipping-slider-image {
    max-width: 95%
  }
}

.priority-shipping-swiper .swiper-wrapper .swiper-slide {
  height: initial;
}

.nz-terms-and-conditions h3 {
  display: inline-block;
}

.ol-nest {
  counter-reset: item;
  padding-left: 0px;
}

.ol-nest>li {
  display: table;
}

.ol-nest>li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  display: table-cell;
  padding-right: 5px;
}

.ant-anchor-link-active {
  background-color: #CB0000 !important;
}

.ant-anchor-link-active .shiitake-children {
  color: white !important;
}

.ant-anchor-link {
  background-color: #F5F5F5;
  padding: 8px 10px;
}

.ant-anchor-link .shiitake-children {
  color: black;
}

.ant-anchor-link.black-href.h1 {
  padding-left: 10px;
}

.ant-anchor-link.black-href.h2 {
  padding-left: 20px;
}

.ant-anchor-link.black-href.h3 {
  padding-left: 30px;
}

.ant-anchor-link.black-href.h4 {
  padding-left: 40px;
}

.ant-anchor-link.black-href.h5 {
  padding-left: 50px;
}

.ant-anchor-link.black-href.h6 {
  padding-left: 60px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}