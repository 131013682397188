html body {
  font-size: 16px;
}

:root {
  --swiper-navigation-size: 30px !important;
}

::selection {
  background: #3297FD;
}

.black-href {
  color: #000000 !important;
}
.black-href:hover {
  color: #d92923 !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #ffeae6;
}
.ant-menu-item > a:hover {
  color: #cb0000;
}

.ant-legacy-form-item label {
  font-size: 16px;
}
.ant-legacy-form-item-label {
  text-align: left;
  height: 30px;
}

/*Header*/
.search-form .anticon-search {
  font-size: 25px !important;
}
.search-form .ant-input-search-button {
  color: #cb0000 !important;
  background: white;
  border-color: #d9d9d9;
}

.ant-input-search .ant-input-group {
  height: inherit;
}
.ant-input-search .ant-input-group .ant-input {
  height: inherit;
}
.ant-input-search .ant-input-group .ant-input-group-addon {
  height: inherit;
}
.ant-input-search .ant-input-group .ant-input-group-addon > button {
  height: inherit;
}

@media (min-width: 768px) {
  .logo-link {
    width: 350px ! important;
  }
}
@media (max-width: 575px) {
  .xs-logo {
    line-height: 64px ! important;
  }
}
@media (min-width: 576px) {
  .xs-logo {
    display: table-cell ! important;
    width: auto ! important;
  }

  .search-form {
    display: table-cell ! important;
    vertical-align: middle;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .search-form {
    padding: 0px 10px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .navMenu .ant-row {
    font-size: 14px;
  }
  .account-button > div {
    font-size: 14px !important;
  }
}

/*Disable sticky banner background `background-attachment: fixed` on mobile*/
@media (max-width: 1119px) {
  .sticky-background {
    background: none ! important;
  }
}

.img-fluid {
  width: 100%;
  height: auto;
}
.am-navbar .am-navbar-title {
  flex: 24;
}
.ant-layout-sider-children .ant-menu-inline-collapsed {
  width: 0px;
}
.ant-layout .ant-layout-header {
  height: auto;
}
.left-drawer .ant-drawer-content-wrapper {
  width: unset !important;
  min-width: 280px;
  max-width: 300px;
}
.left-drawer .ant-drawer-header {
  background: #cb0000;
  border: none;
  border-radius: 0;
}
.left-drawer .ant-drawer-header .ant-drawer-title button:hover{
  color: #cb0000 !important;
}
.left-drawer .ant-drawer-header-title {
  flex: unset;
  align-items: unset;
  justify-content: center;
}
.left-drawer .ant-menu-title-content {
  line-height: 1;
}
@media (min-width: 301px) {
  .left-drawer .ant-drawer-close {
    display: none !important;
  }
}
.left-drawer .ant-drawer-close {
  color: white;
  width: 40px;
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  height: 100%;
  margin: 0;
}

.topnav {
  font-weight: bold;
}
.topnav.ant-dropdown-menu-item:hover {
  background-color: unset;
  color: #cb0000;
}
.topnav.ant-menu-submenu {
 position: static;
}
.topnav .ant-menu-vertical.ant-menu-sub {
 margin-left: 0px;
 width: 900px;
 column-count: 4;
}

.navgroup .ant-menu-item-group-title {
  display: none !important;
}
.navgroup .ant-menu-item-group-list {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
}
.navgroup .ant-menu-item {
  padding: 0px;
}
.navgroup .ant-menu-item-selected {
  background-color: unset !important;
}
.navgroup .ant-menu-item:active {
  background: unset;
}

.ant-menu-item[url="/category/clearance-sale"] {
  background-color: #cb0000;
}

.ant-menu-item[url="/category/clearance-sale"] a {
  border :none ! important;
  line-height: 30px ! important;
  height: 30px ! important;
  color: white;
}

li.ant-menu-item.topnav:nth-last-of-type(2) a {
  border-bottom: none ! important;
}

#nprogress .bar {
  background: white ! important;
}
#nprogress .spinner-icon {
  border-top-color: white ! important;
  border-left-color: white ! important;
}
#nprogress .peg {
  box-shadow: 0 0 10px white, 0 0 5px white ! important;
}

.carousel.carousel-slider .slide {
 background: transparent;
}

.am-drawer-content .am-drawer-draghandle {
 background-color: transparent;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.product-suggestions .product-label {
  font-size: 11px;
}

.product-label {
  z-index: 3;
  position: absolute;
  text-align: left;
  line-height: 14px;
  font-weight: bold;
  text-shadow: 1px 1px white;
}

.product-label-background {
  background-color: #ffe500;
}
.product-label-top {
  top: 0px;
}
.product-label-bottom {
  bottom: 0px;
}
.product-label > div {
  padding: 2px;
  line-height: 15px;
}
.product-label-left {
  left: 0;
}
.product-label-right {
  right: 0;
}

.bonus-label-list + .bonus-label-list {
  margin-left: 5px;
}

.price {
  color: #cb0000;
  font-weight: bold;
}

.discount.price {
  color: green;
}

.optional-accessories .ant-collapse-content-box {
  max-height: 250px;
  overflow: auto;
}

.ant-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, .25);
}

.swiper-button-prev:hover, .swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  opacity: 0.5;
  color: #cb0000 !important;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  opacity: 0.5;
  color: #cb0000 !important;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
}

/*Product Page*/
.swiper-pagination-bullet-active {
  background: white;
}
.offer-pagination-bullet-active {
  opacity: 1;
  background: #cb0000;
}
.offer-pagination-horizontal {
  bottom: 0px !important;
  line-height: 1;
}
.swiper-container-thumb-horizontal .swiper-slide {
  margin-right: 5px;
}
.swiper-container-thumb-horizontal .swiper-slide img {
  border: 1px solid #ededed;
}
.swiper-container-thumb-horizontal .swiper-slide.swiper-slide-active img {
  border: 1px solid red;
}
.absolute-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 100%;
}

.click-state:active {
  transform: translate(0, 2px);
}

.click-state.inverse:hover, .account-button:hover {
  cursor: pointer;
  background-color: white ! important;
  color: black ! important;
}

.click-state.priority:hover {
  cursor: pointer;
  background-color: #0089B6 ! important;
}

@media (max-width: 810px) {
  .navMenu .navLink {
    font-size: 13px !important;
  }
}

.navMenu .navLink {
  font-size: 14px;
  color: white;
  display: block;
}

.toc::-webkit-scrollbar {
  width: 10px;
}

.toc::-webkit-scrollbar-track {
  background: none;
}

.toc::-webkit-scrollbar-thumb {
  background: rgba(128,134,139,.26);
  border-radius: 8px;
}

.toc-affix .ant-affix {
  z-index: 1;
}

.ant-affix .ant-anchor-wrapper {
  margin-top: 0px ! important;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

ul.cart .ant-menu-item:hover {
  color: inherit;
}
ul.cart .ant-menu-item:active {
  background: none;
}
.plus-cart {
  transition: all .2s ease-in-out;
}
.plus-cart:hover {
  transform: scale(1.1);
}

.category-breadcrumb a {
  color: black;
  font-size: 24px;
}

.StripeElement {
  display: block;
  padding: 10px 14px;
  font-size: 1em;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 0px;
  background: white;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

#shippingMethod > label > span:first-of-type {
  max-width: 10%;
}

#shippingMethod > label > span:last-of-type {
  max-width: 94%;
  display: inline-block;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.payment-logo {
  margin-right: 5px;
}

.grecaptcha-badge {
  z-index: 3;
}

.chat-button:hover {
  transform: scale(1.1);
}

.chat-button img {
  width: 100%;
  height: auto;
}

@media (max-width: 576px) {
  .ant-pagination.long > .ant-pagination-total-text {
    display: block ! important;
  }
}
