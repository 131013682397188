.autocomplete-select-option:hover{
  background-color: white;
}

.autocomplete-select-option:active {
  background-color: white;
}

.search-bar .ant-select-item {
    padding: 0;
}

.search-bar .ant-select-item-option-active:first-child:not(.ant-select-item-option-disabled) {
  background-color: white;
  cursor: auto;
}

.search-bar .rc-virtual-list-holder {
  max-height: none !important;
}

.search-bar .rc-virtual-list-scrollbar {
  display: none;
}

.search-bar .ant-btn {
  box-shadow: none;
  padding: 0;
  border: 0;
  margin: 0 10px;
  font-size: smaller;
}

.search-bar .ant-btn:hover {
  background-color: transparent !important;
}