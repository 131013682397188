.offer-panel .ant-collapse-header {
  background-color: #cb0000;
  color: #fff !important;
  font-size: 14;
  font-weight: 700;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.offer-progress .ant-progress-bg {
  height: 22px !important;
}
