.stock-popover .ant-popover-buttons button:first-of-type {
  display: none;
}

.product-suggestions .product-info h2 {
  height: 38px;
}

.product-list .ant-card {
  border-radius: 0px;
}
@media (min-width: 992px) {
  .product-list > .ant-col-lg-6 {
    max-width: 20%;
    width: 20%;
  }
}

.product-info h2 {
  margin-bottom: 7px;
  height: 29px;
  font-weight: normal;
}
.product-info h2 a {
  overflow: hidden;
  font-size: 13px;
  line-height: 15px;
  display: block;
}
.product-info-list h2 {
  margin-bottom: 7px;
}
.product-info-list h2 a {
  overflow: hidden;
  font-size: 13px;
  line-height: 15px;
  display: block;
}

.tier-price-table {
  font-weight: bold;
  margin-top: 10px;
}
.tier-price-table .ant-table-thead > tr > th,
.tier-price-table .ant-table-thead > tr > th.ant-table-column-sort {

  background: #cb0000;
  font-weight: bold;
  color: white;
}

/* Remove spinners on nput type=number*/
.input-quantity .ant-input-number-input::-webkit-outer-spin-button,
.input-quantity .ant-input-number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.input-quantity .ant-input-number-input {
  text-align: center;
  -moz-appearance:textfield;
}

@media (max-width: 992px) {
  .product-page .product-name {
    font-size: 16px ! important;
  }
}

.inStock {
  padding: 5px;
  background: green;
}

.inStock span {
  color: white;
}

.lowInStock {
  padding: 5px;
  background: green;
}

.lowInStock span {
  color: white;
}

.outOfStock {
  padding: 5px;
  background: #cb0000;
}

.outOfStock span {
  color: white;
}

.filter-border-icon > svg {
  border: 1px solid currentColor;
}

.filter-checkbox > span:first-child {
  top: unset;
}
.filter-checkbox > span:nth-child(2) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.filter-drawer-checkbox:hover {
  color: #cb0000;
}
.filter-drawer-checkbox > span:first-child {
  top: unset;
}
.filter-drawer-checkbox > span:nth-child(2) {
  width: 100%;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.price-filter-checkbox .ant-checkbox-wrapper {
  font-size: 14px;
  margin-left: 0px;
  margin-right: 0px;
  min-height: 35px;
  align-items: center;
}
.price-filter-checkbox > label {
  width: 100%;
}
.price-filter-checkbox > label > span:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.price-filter-checkbox .ant-checkbox-group-item > span:not(.ant-checkbox) {
  width: 100%;
}

.price-filter-popover .ant-popover-inner-content {
  padding: 12px 0px 12px 12px;
}

.drawer-filter-menu .ant-menu-item {
  height: 50px;
}
.drawer-filter-menu .ant-menu-item .ant-menu-title-content {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.drawer-filter-menu .ant-menu-item-selected {
  background-color: inherit !important;
}

.dropdown-filter {
  padding-top: 0;
}
.dropdown-filter .ant-popover-content .ant-popover-arrow {
  display: none;
}
.dropdown-filter .ant-popover-content .ant-popover-inner-content {
  padding: 5px 10px;
}
.dropdown-filter .ant-popover-content .ant-popover-inner .ant-popover-title {
  display: flex;
  align-items: end;
}
button[class~="dropdown-filter-btn"][ant-click-animating-without-extra-node='true']::after {
  display: none;
}
.dropdown-filter-arrow {
  transition: transform 0.3s ease 0s !important;
  font-style: normal;
  text-transform: none;
  vertical-align: -0.125em;
  position: absolute !important;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}

.filter-panel-priority .ant-collapse-header {
  background-color: #0089B6 !important;
  color: white !important;
}

.mobile-filter .headroom--unpinned {
  position: fixed !important;
  transform: translate3d(0px, -100%, 0px) !important;
}
.mobile-filter .headroom--pinned {
  background: rgb(240, 240, 240);
  position: fixed !important;
  transform: translate3d(0px, 137px, 0px) !important;
}

.dropdown-filter .ant-checkbox-group .ant-checkbox-wrapper-checked {
  background-color: #F3F3F3;
}
