.ant-pagination-total-text {
  display: inline !important;
}

.ant-pagination-item-link {
  background-color: rgb(203, 0, 0) !important;
  color: white !important;
  border: none !important;
}

.ant-pagination-item:not(.ant-pagination-item-active) {
  border: none !important;
}

.ant-pagination-item-active {
  border: 1px solid #c9c9c9 !important;
  color:black !important;
  background-color: #F5F5F5 !important;
}
