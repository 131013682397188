.alphabet-selector {
  overflow: hidden;
  background-color: #e5e5e57e;
  position: fixed;
  border-radius: 10px;        
  transition: top 0.07s ease-out, bottom 0.07s ease-out;
}

.alphabet-selector-text {
  font-size: 15;
}

.alphabet-selector-button {
  vertical-align: top;
  cursor: pointer;
  border-style: hidden;
  color: #333333;
  width: 100%;
  padding: 2px 6px;
}

.alphabet-selector-button:hover, .active {
  background-color:  #c2c2c2;
}

.brand-highlight {
  background-color: #ffe2e2;
}

@media (max-width: 768px) {
  .alphabet-selector {
    max-width: 100%;
  }
}

@media (max-height: 820px) {
  .alphabet-selector {
    max-height: 90%;
    overflow: scroll;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}