.account-nav {
  padding-left: 5px !important;
}

.address-card .ant-card-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.otp-input, ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
