@media (min-width: 768px) {
  .store-list {
    max-height: 700px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.checkout {
  font-size: 14px;
}

.location-btn:hover {
  z-index: 1;
}
.location-btn:focus {
  z-index: 1;
}
