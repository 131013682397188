.bigbrands {
  background-color: white;
}

.bigbrands a {
  display: block;
  text-align: center;
}

.bigbrands a img {
  display: block;
}

.bigbrands a:hover {
  transition: all .2s ease-in-out;
  transform: scale(1.05);
  position: relative;
  z-index: 1;
}
