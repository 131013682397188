.footer a {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: rgb(0, 0, 0, 0.90);
}

.footer .stay-connected a {
  font-size: 40px;
  display: inline-block;
  transition: all .2s ease-in-out;
}

.footer .stay-connected a:hover {
  transform: scale(1.1);
}

.footer .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0px;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  color: #CB0000;
}

@media (max-width: 469px) {
  .footer-payment {
    justify-content: center !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .footer .stay-connected {
    align-items: center;
  }
}

@media (max-width: 767px) {
  .footer a {
    font-size: 13px;
  }
}

@media (min-width: 576px) {
  .footer-end-padding {
    text-align: end;
    padding-left: 10px;
  }
}

@media (max-width: 575px) {
  .footer-center {
    text-align: center;
  }
  .payment-center {
    justify-content: center !important;
  }
}

.small-placeholder::placeholder {
  font-size: 80%;
}
